<template>
	<div class="zdy_search" >
		<el-form :inline="true" :model="search_data" >
			<el-form-item label="用户名称">
				<el-input v-model="search_data.user_name" size="mini" placeholder="请输入用户名称" />
			</el-form-item>
			<el-form-item label="是否是业务员">
				<!-- <el-input v-model="search_data.user_name" size="mini" placeholder="请输入用户名称" /> -->
				<el-select v-model="search_data.is_admin" class="m-2" placeholder="请选择" size="mini">
				    <el-option
				      v-for="item in options"
				      :key="item.value"
				      :label="item.label"
				      :value="item.value"
				    />
				  </el-select>
			</el-form-item>
			
			<el-form-item label="开始截至时间">
				<!-- <el-input v-model="search_data.user_name" size="mini" placeholder="请输入用户名称" /> -->
				<el-date-picker
				   v-model="search_data.date_time"
				   type="daterange"
				   range-separator="To"
				   start-placeholder="开始时间"
				   end-placeholder="截至时间"
				   size="mini"
				   value-format="YYYY-MM-DD"
				/>
			</el-form-item>
			<el-form-item >
				<el-button type="primary" size="mini" @click="onSearch">搜索</el-button>
			</el-form-item>
		</el-form>
		<div class="hr"></div>
		<div class="use_btn">
			<el-button size="mini" type="primary" @click="add('')">新增</el-button>
			<el-button size="mini" type="" @click="exportData('')">导出</el-button>
		</div>
		
	</div>
	<div class="zdy_table">
		<!-- <div style="background: white;padding: 10px;width: 95%;margin-left: 2.5%;">数据</div> -->
		<el-table :data="tableData" border  style="width: 95%;margin-left: 2.5%;" v-loading="loading">
			<el-table-column prop="user_name" label="头像" >
				 <template #default="scope" >
					 <img :src="scope.row.img" style="height: 100px;width: 100px;"/>
				 </template>
			</el-table-column>
		    <el-table-column prop="user_name" label="用户名" />
			<el-table-column prop="price" label="下单金额" />
			<el-table-column prop="update_time" label="登陆/修改时间" />
			<!-- <el-table-column prop="is_admin_str" label="是否是管理员" /> -->
			<el-table-column prop="is_admin_str" label="是否是管理员" >
				 <template #default="scope" >
					 {{scope.row.is_admin_str}}
					 <el-popconfirm title="是否修改为管理员？" @confirm="update(scope.row)" confirm-button-text="是" cancel-button-text="否">
					 	<template #reference>
					 		<el-button link  size="mini" >
					 			修改管理员
					 		</el-button>
					 	</template>
					 </el-popconfirm>
				 </template>
			</el-table-column>
		   <!-- <el-table-column fixed="right" label="操作" >
		      <template #default="scope" >
		        <el-button link type="primary"  size="mini" @click="add(scope.row.uuid)"><el-icon><EditPen /></el-icon></el-button>
				<el-popconfirm title="是否删除该数据？" @confirm="del(scope.row.uuid)" confirm-button-text="是" cancel-button-text="否">
					<template #reference>
						<el-button link type="danger" size="mini" >
							<el-icon><DeleteFilled /></el-icon>
						</el-button>
					</template>
				</el-popconfirm>
		      </template>
		    </el-table-column> -->
		  </el-table>
	</div>
	<div class="zdy_page">
		<page-commom :search_data="search_data" @page_search='pageSearch'></page-commom>
	</div>
</template>

<script setup>
import {ref,reactive,toRef,inject,onMounted} from 'vue';
import pageCommom from '@/components/commom/pageCommom'
import fs from 'file-saver'
import  * as XLSX from "xlsx";
onMounted(()=>{
	onSearch()
})
const show=ref(false);
const $get=inject('$get');
const $post=inject('$post');
const $success=inject('$success');
const $error=inject('$error');
const loading=ref(false);
let uuid=ref('');

const options=ref([{
	"label":"请选择","value":"0"
},{
	"label":"是","value":"1"
},{
	"label":"否","value":"2"
}])
const search_data=ref({
	'name':'',
	'is_admin':"",
	'date_time':[],
	'page':1,
	'limit':10,
	'total':0,
});
var tableData = ref([]);
const pageSearch=(data)=>{
	search_data.value=data.value
	onSearch();
}
const add=(val)=>{
	uuid.value=val
	show.value=!show.value;
}
const exportData=async ()=>{
	loading.value=true;
	let export_search_data=search_data.value;
	export_search_data.limit="99999";
	const res=await $post('/api/WXUser/Export',export_search_data)
	export_search_data.limit="10";
	loading.value=false;
	
	const data = XLSX.utils.json_to_sheet(res.result)//此处tableData.value为表格的数据
	const wb = XLSX.utils.book_new()
	XLSX.utils.book_append_sheet(wb, data, '用户表')//test-data为自定义的sheet表名
	XLSX.writeFile(wb,'用户表.xlsx')//test.xlsx为自定义的文件名
}
const update=async (item)=>{
	const is_admin=item.is_admin=="1"?"2":"1";
	const res=await $post('/api/WXUser/Edit',{"uuid":item.uuid,"is_admin":is_admin})
	onSearch();
}
const onSearch=async ()=>{
	loading.value=true;
	const res=await $get('/api/WXUser/GetList',search_data.value)
	loading.value=false;
	search_data.value.total=res.result.total;
	tableData.value=res.result.data;
}
const del= async (val)=>{
	const res=await $get('/api/WXUser/Delete',{"uuid":val})
	if(res.errCode=="0") $error(res.msg);
	$success(res.msg);
	onSearch();
}
const close=()=>{
	show.value=!show.value;
	onSearch();
}
</script>
	
	
<style scoped>
.flex{
	display: flex;
}
.hr{
	border-bottom: 1px solid #edf0f6;
}
.use_btn{
	margin-top: 10px
}
.zdy_search {
	background: white;overflow: hidden;
	padding: 10px;border: 1px solid #edf0f6;
}
.zdy_table{
	margin-top: 10px;
}
.zdy_page{
	padding: 10px;width: 95%;margin-left: 2.5%;text-align: right;
}
</style>
